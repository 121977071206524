<template>

     <!-- Footer -->
    <section id="white" class="gla_section gla_image_bck" data-color="#fff" style="border-top: 1px solid;">
        <div class="container " style="padding-top: 0px;
            padding-bottom: 50px;">
            <div class="row">
                <div class="col-md-4 col-sm-4">

                    <br>
                    <br>
                    <h4>Contact Us</h4>
                    <p>
                    68 Cardamon Place, Melbourne Vic 3000<br>
                    Call us: 1.777.77.777<br>
                    <a href="mailto:hello@glanztheme.com">hello@glanztheme.com</a>
                    </p>

                    <form action="#">


                    </form>

                </div>
                <div class="col-md-8 col-sm-8">
                   <partners/>
                </div>

            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="gla_block">

                    </div>
                </div>
            </div>

        </div>
    </section>
    <!-- Footer End -->

    <!-- social Footer -->
    <section id="simple" class="gla_image_bck gla_section_extra_sml socialbackground " data-color="#e5e5e5" >
        <div class="container" style="padding-bottom: 0px;">
            <div class="row">
                <div class="col-md-4 col-sm-4">
                    <p>© Glanz. 2017</p>
                </div>
                <div class="col-md-4 col-sm-4 text-center">
                    <div class="gla_social_btns">
                        <a href="#"><i class="ti ti-twitter"></i></a>
                        <a href="#"><i class="ti ti-facebook"></i></a>
                        <a href="#"><i class="ti ti-youtube"></i></a>
                        <a href="#"><i class="ti ti-jsfiddle"></i></a>
                        <a href="#"><i class="ti ti-vimeo"></i></a>
                        <a href="#"><i class="ti ti-pinterest"></i></a>
                        <a href="#"><i class="ti ti-instagram"></i></a>
                        <a href="#"><i class="ti ti-soundcloud"></i></a>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4">

                </div>
            </div>


        </div>
    </section>
    <!-- social Footer End -->






</template>

<script>
import partners from './partners.vue'
    export default {
        name: 'footers',
        components: {
            partners
        }
    }
</script>

<style scoped>
    .socialbackground {
        background-color: rgb(229, 229, 229);
    }
</style>