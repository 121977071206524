<template>
   <section class="gla_section gla_image_bck gla_wht_txt gla_fixed text-center " style="background-color: #d8e5e9;" >
   <h3 style="font-weight: 600;" >Our Gallery</h3>
  <div class="row" style="margin-top: 30px; background-color: #d8e5e9;"  >
    <!--item-->
    <div class="col-md-4 col-lg-4 col-sm-12">

        <div class="containerr">
          <img src="@/assets/trustimages/image15.jpg" alt="Avatar" class="imagee">
          <div class="overlayy">
            <div class="textt">Hello World</div>
          </div>
        </div>


    </div>
    <!--item end-->

    <!--item-->
    <div class="col-md-4 col-lg-4 col-sm-12">

        <div class="containerr">
          <img src="@/assets/trustimages/image16.jpg" alt="Avatar" class="imagee">
          <div class="overlayy">
            <div class="textt">Hello World</div>
          </div>
        </div>


    </div>
    <!--item end-->


    <!--item-->
    <div class="col-md-4 col-lg-4 col-sm-12">

        <div class="containerr">
          <img src="@/assets/trustimages/image17.jpg" alt="Avatar" class="imagee">
          <div class="overlayy">
            <div class="textt">Hello World</div>
          </div>
        </div>


    </div>
    <!--item end-->

  </div>
   </section>
</template>

<script>
export default {};
</script>

<style scoped>
    .containerr {
      position: relative;
      width: 105%;
    }

    .imagee {
      display: block;
      width: 100%;
      height: 50%;
    }

    .overlayy {
      position: absolute;

      bottom: 0;
      left: 0;
      right: 0;
      height: 10%;
      width: 100%;
      opacity: 0.5;
      transition: .5s ease;
      background-color: #000;
    }

    .containerr:hover .overlayy {
      opacity: 0.5;

      height: 20%;
    }

    .textt {
      color: white;
      font-size: 20px;
      font-weight: 700;
      position: absolute;
    font-family: "Montserrat",sans-serif;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center;
    }

</style>
