<template>
    <!-- about us -->



    <section style="background-image:url('https://www.mgnevents.co.uk/wp-content/uploads/2017/08/60th-Wedding-Anniversary-Kent-017.jpg');/* background-position:50% 0px; */height: 194vh !important;background-size: cover;background-repeat: no-repeat;background-position: center center; height: 100vh !important;">







        <!-- Over -->

        <div>



            <div class="row">



                <div class="col-md-6 col-sm-0">



                </div>



                <div class="col-12 col-md-6 overlay ">



                    <div class="grid-container  textAlignCenter">



                        <div class="grid-100">







                            <div class="wysiwyg animate fadeIn fromTop delay2">

                                <h3 style="font-weight: 400;" >About Us</h3>

                                <p style="font-weight: 400;">An exceptional, entertaining event shouldn’t be time-consuming or stressful.</p>



                                <p style="font-weight: 400;">At MGN events, we bring your ideas to life and do ALL the heavy lifting so you don’t have to.</p>



                                <p style="font-weight: 400;">Our bespoke end-to-end event management and production services use fresh, innovative ideas to delight and engage your crowd.</p>






                                <center><button type="button" class="btn btn-primary">Primary</button></center>





                            </div>



                        </div>



                        <div class="clear"></div>



                    </div>



                </div>



            </div>



        </div>



        <!-- container end -->



    </section>



    <!-- about us end -->
</template>

<script>
export default {

}
</script>

<style scoped>
.gla_section .container {
    padding-top: 0px;
    padding-bottom: 0px;
}

.overlay {
    right: 15px;
    transition: right 0.7s ease-out;
    background-color: rgba(0, 0, 0, 0.780392);
    color: #fff;
    /* position: absolute; */
    top: 0;
    height: 100vh;
    bottom: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
}
</style>