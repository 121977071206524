<template>
    <div>

        <!-- services -->
        <section class="gla_section gla_image_bck" data-color="#d8e5e9">



            <div class="container text-center">

                <h2 style="font-weight: 800;">Our Services</h2>
                <p>From wedding venues to wedding dress boutiques, wedding invitations to wedding bands, search our comprehensive supplier listings and compare by price, availability, location and reviews</p>

                <div class="row gla_shop">


                        <div class="col-md-3 gla_anim_box">
                            <div class="gla_shop_item">
                                <span class="gla_shop_item_slider">
                                    <img src="@/assets/trustimages/image11.jpg" alt="">
                                </span>

                                <a href="#" class="gla_shop_item_title">
                                    Wedding Dresses
                                </a>
                            </div>
                        </div>

                        <div class="col-md-3 gla_anim_box">
                            <div class="gla_shop_item">
                                <span class="gla_shop_item_slider">
                                    <img src="@/assets/trustimages/image12.jpg" alt="">
                                </span>

                                <a href="#" class="gla_shop_item_title">
                                    Photography
                                </a>
                            </div>
                        </div>

                        <div class="col-md-3 gla_anim_box">
                            <div class="gla_shop_item">
                                <span class="gla_shop_item_slider">
                                    <img src="@/assets/trustimages/image13.jpg" alt="">
                                </span>

                                <a href="#" class="gla_shop_item_title">
                                    Music & Entertainment
                                </a>
                            </div>
                        </div>

                        <div class="col-md-3 gla_anim_box">
                            <div class="gla_shop_item">
                                <span class="gla_shop_item_slider">
                                    <img src="@/assets/trustimages/image14.jpg" alt="">
                                </span>

                                <a href="#" class="gla_shop_item_title">
                                    Venues
                                </a>
                            </div>
                        </div>


                </div>
                <!-- row end -->

                <p><a href="#" class="btn">More Suppliers</a></p>

            </div>
            <!-- container end -->

        </section>
        <!-- services-->
    </div>
</template>

<script>
    export default {

    }
</script>

<style lang="scss" scoped>

</style>