<template>
  <div>
    <!-- Header -->
     <header class="gla_nav_abs">



        <nav class="gla_light_nav">

            <div class="container">

                <div class="gla_logo_container clearfix">
                    <img src="images/glanz_logo_bl.png" alt="">
                    <div class="gla_logo_txt">
                        <!-- Logo -->
                        <a href="/" class="gla_logo">Ian & Kelsey</a>

                        <!-- Text Logo -->
                        <div class="gla_logo_und">August 10, 2017</div>
                    </div>
                </div>

                <!-- Menu -->
                <div class="gla_main_menu gla_main_menu_mobile">

                    <div class="gla_main_menu_icon">
                        <i></i><i></i><i></i><i></i>
                        <b>Menu</b>
                        <b class="gla_main_menu_icon_b">Back</b>
                    </div>
                </div>





                <!-- Top Menu -->
                <div class="gla_default_menu">
                    <ul>
                        <li ><a href="/">Home</a>

                        </li>

                        <li ><a href="/gallery">Gallery</a>

                        </li>

                        <li class="gla_parent"><a href="/services">Services</a>
                            <ul>
                                <li><a href="/service1">Services 1</a></li>
                               <li><a href="#">Services 1</a></li>
                               <li><a href="#">Services 1</a></li>

                            </ul>
                        </li>

                        <li class="gla_parent"><a href="/Blogs">Blog</a>
                            <ul>
                                <li><a href="Blog1">Blog 1</a></li>
                                <li><a href="03_02_blog_1col.html">Blog 2</a></li>
                                <li><a href="03_03_blog_single_sidebar.html">Blog 3</a></li>
                                <li><a href="03_04_blog_single_col1.html">Blog 4</a></li>
                            </ul>
                        </li>
                        <li ><a href="/contactus">Contact Us</a>

                        </li>


                    </ul>
                </div>
                <!-- Top Menu End -->



            </div>
            <!-- container end -->
        </nav>

    </header>
    <!-- Header End -->

  </div>
</template>

<script>
export default {
  created(){
    setTimeout(() => {
      $(".gla_fixed").css("background-attachment","fixed");


      /* Submenu */
      $('.gla_parent').on({
        mouseenter:function(){
          $(this).find('ul').addClass('active');
        },mouseleave:function(){
          $(this).find('ul').removeClass('active');
        }
      });
      $('.gla_search_parent').on({
        mouseenter:function(){
          $(this).find('ul').addClass('active');
        },mouseleave:function(){
          $(this).find('ul').removeClass('active');
        }
      });

      /* Music */
      $('.gla_music_icon').on('click',function(){
        $('.gla_music_icon_cont').fadeToggle();
      });

      /* Mobile Menu */

      $('.gla_main_menu_content_menu .gla_parent').on("click", function(e){
        $(this).find('ul').slideToggle(300);
      });
      $('.gla_mobile_menu').on("click", function(e){
        $(this).toggleClass('active');
        $('.gla_mobile_menu_hor').toggleClass('active');
      });
      $('.gla_header_search span').on("click", function(e){
        $(this).next('.gla_header_search_cont').fadeToggle();
      });

    }, 300);
  }
}
</script>

<style lang="scss" scoped></style>
