<template>
    <div class="home">

        <headers/>
        <slider/>
        <services/>
        <about_us/>
        <gallery/>
        <blog/>
        <footers/>

    </div>
</template>

<script>
// @ is an alias to /src
import headers from '@/components/header/menu.vue'
import Slider from '../components/slider/slider.vue'
import Services from '../components/services/services.vue'
import about_us from '../components/about_us/about_us.vue'
import gallery from '../components/gallery/gallery.vue'
import Blog from '../components/blogs/blog.vue'
import Footers from '../components/footer/footer.vue'

export default {
    name: 'Home',
    components: {
        headers,
        Slider,
        Services,
        Blog,
        about_us,
        gallery,
        Footers
    }
}
</script>
