import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',

    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/services',
    name: 'OurService',

    component: () => import(/* webpackChunkName: "about" */ '../views/services.vue')
  },
  {
    path: '/service1',
    name: 'service1',
    component: () => import(/* webpackChunkName: "about" */ '../views/service1.vue')
  },

  {
    path: '/Blogs',
    name: 'Blogs',
    component: () => import(/* webpackChunkName: "about" */ '../views/blogs.vue')
  },

  {
    path: '/Blog1',
    name: 'Blog1',
    component: () => import(/* webpackChunkName: "about" */ '../views/blog1.vue')
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: () => import(/* webpackChunkName: "about" */ '../views/gallery.vue')
  },
  {
    path: '/contactus',
    name: 'contactus',
    component: () => import(/* webpackChunkName: "about" */ '../views/contactus.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
