<template>
<br>
    <br>
    <h4>Our Partners</h4>
    <div>
        <!-- icon boxes -->
        <div class="gla_icon_boxes gla_partnerss row gla_overr" style=" margin-top: 28px;">


            <!-- item -->
            <div class="gla_partner_box">
                <a href="#"><img src="@/assets/trustimages/image18.jpg" height="80" alt=""></a>
            </div>

            <!-- item -->
            <div class="gla_partner_box">
                <a href="#"><img src="@/assets/trustimages/image19.jpg" height="80" alt=""></a>
            </div>

            <!-- item -->
            <div class="gla_partner_box">
                <a href="#"><img src="@/assets/trustimages/image21.jpg" height="80" alt=""></a>
            </div>

            <!-- item -->
            <div class="gla_partner_box">
                <a href="#"><img src="@/assets/trustimages/image21.jpg" height="80" alt=""></a>
            </div>




        </div>
        <!-- icon boxes end -->
    </div>
</template>

<script>
export default {
    created() {
        setTimeout(() => {

            $(".gla_partnerss").owlCarousel({
                navigation: true,
                responsive: true,
                responsiveRefreshRate: 200,
                responsiveBaseElement: window,
                slideSpeed: 200,
                addClassActive: true,
                paginationSpeed: 200,
                rewindSpeed: 200,
                items: 3,
                autoPlay: true,
                autoHeight: true,
                touchDrag: true,
                navigationText: [
                    '<i class="ti ti-angle-left"></i>',
                    '<i class="ti ti-angle-right"></i>',
                ],
            });

            $('.gla_overr').each(function(){
                var color = $(this).attr('data-color');
                var image = $(this).attr('data-image');
                var opacity = $(this).attr('data-opacity');
                var blend = $(this).attr('data-blend');
                var gradient = $(this).attr('data-gradient');
                if (gradient){
                    $(this).css('background-image', gradient);
                }
                if (color){
                    $(this).css('background-color', color);
                }
                if (image){
                    $(this).css('background-image', 'url('+image+')');
                }
                if (opacity){
                    $(this).css('opacity', opacity);
                }
                if (blend){
                    $(this).css('mix-blend-mode', blend);
                }
            });
        }, 444);
    },
};
</script>

<style lang="scss" scoped>

</style>