<template>
  <!-- Slider -->

  <div class="gla_slider gla_wht_txt">
    <!-- Slider -->
    <div class="gla_slider_carousel">
      <div class="gla_slider gla_image_bck" :data-image="require(`@/assets/trustimages/image7.jpg`) ">
        <div class="gla_over" data-color="#000" data-opacity="0.1"></div>

        <div class="container">
          <!-- Slider Texts -->
          <div class="gla_slide_txt gla_slide_center_middle text-center">
            <div class="gla_slide_title">Kelly & Rob</div>
          </div>
          <!-- Slider Texts End -->
        </div>
        <!-- container end -->
      </div>
      <!-- Slider End -->

      <div class="gla_slider gla_image_bck" :data-image="require(`@/assets/trustimages/image5.jpg`) ">
        <div class="gla_over" data-color="#000" data-opacity="0.1"></div>

        <div class="container">
          <!-- Slider Texts -->
          <div class="gla_slide_txt gla_slide_center_middle text-center">
            <div class="gla_slide_title">Save the Date<br />August 10, 2017</div>
          </div>
          <!-- Slider Texts End -->
        </div>
        <!-- container end -->
      </div>
      <!-- Slider End -->
    </div>
    <!-- carousel end -->

    <!-- Slide Down -->
    <a class="gla_scroll_down gla_go" href="#gla_content">
      <b></b>
      Scroll
    </a>
  </div>
  <!-- Slider End -->
</template>

<script>
export default {
  data()
  {

  },
  created() {

    setTimeout(() => {
      if ($(".gla_slider_carousel .gla_slider").length > 1) {
        if ($("#video_background").length == 1) {
          $(".gla_slider_carousel").owlCarousel({
            navigation: true,
            pagination: false,
            responsive: true,
            responsiveRefreshRate: 200,
            responsiveBaseElement: window,
            slideSpeed: 200,
            addClassActive: true,
            paginationSpeed: 200,
            rewindSpeed: 200,
            items: 1,
            autoPlay: false,
            touchDrag: true,
            singleItem: true,
            navigationText: [
              '<i class="ti ti-angle-left"></i>',
              '<i class="ti ti-angle-right"></i>',
            ],
            transitionStyle: "fade",
            afterAction: function (current) {
              current.find("video").get(0).play();
            },
          });
        } else {
          $(".gla_slider_carousel").owlCarousel({
            navigation: true,
            pagination: false,
            responsive: true,
            responsiveRefreshRate: 200,
            responsiveBaseElement: window,
            slideSpeed: 200,
            addClassActive: true,
            paginationSpeed: 200,
            rewindSpeed: 200,
            items: 1,
            autoPlay: false,
            touchDrag: true,
            singleItem: true,
            navigationText: [
              '<i class="ti ti-angle-left"></i>',
              '<i class="ti ti-angle-right"></i>',
            ],
            transitionStyle: "fade",
          });
        }
      }
      /* Section Background */
      $(".gla_image_bck").each(function () {
        var image = $(this).attr("data-image");
        var gradient = $(this).attr("data-gradient");
        var color = $(this).attr("data-color");
        var blend = $(this).attr("data-blend");
        var opacity = $(this).attr("data-opacity");
        var position = $(this).attr("data-position");
        var height = $(this).attr("data-height");
        if (image) {
          $(this).css("background-image", "url(" + image + ")");
        }
        if (gradient) {
          $(this).css("background-image", gradient);
        }
        if (color) {
          $(this).css("background-color", color);
        }
        if (blend) {
          $(this).css("background-blend-mode", blend);
        }
        if (position) {
          $(this).css("background-position", position);
        }
        if (opacity) {
          $(this).css("opacity", opacity);
        }
        if (height) {
          $(this).css("height", height);
        }
      });
    }, 444);
  },
};
</script>

<style scoped>
.gla_slider {
  top: 42px;
}
</style>
